import React, { useState, useEffect } from 'react';
import { v2Colors } from '@web-for-marketing/react-ui';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import { useAtom } from 'jotai';
import { isProgressBarVisibleAtom } from '@atoms/navigation';

const classes = {
    progressBar: {
        width: '100%',
        backgroundColor: v2Colors.background.light,
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: v2Colors.text.activePrimary,
        },
    },
} as const;

export function ProgressScrollBar(): JSX.Element | null {
    const [progress, setProgress] = useState(0);
    const [isProgressBarVisible] = useAtom(isProgressBarVisibleAtom);

    useEffect(() => {
        const updateProgressBar = (): void => {
            const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
            const scrollPercentage = (window.scrollY / scrollableHeight) * 100;
            setProgress(scrollPercentage);
        };

        if (isProgressBarVisible) {
            window.addEventListener('scroll', updateProgressBar);
        } else {
            window.removeEventListener('scroll', updateProgressBar);
        }

        return () => window.removeEventListener('scroll', updateProgressBar);
    }, [isProgressBarVisible]);

    return isProgressBarVisible ? (
        <aside>
            <LinearProgress
                aria-label='page progress'
                variant='determinate'
                value={progress}
                css={classes.progressBar}
                data-testid='progress-bar'
            />
        </aside>
    ) : null;
}
