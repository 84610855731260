import React, { useEffect, useRef } from 'react';
import { MenuNavigationV2 } from '@models/navigationBar';
import { Divider } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { navbarStyles as classes } from './navbarStyles';
import { MicroMenu } from './MicroMenu';
import { MainMenu } from './MainMenu';
import { navHeightAtom, remSizeAtom } from '@atoms/appSettings';
import { getLanguageById } from '@helpers/languages';
import { LanguageCode } from '@models/languageCode';
import { ProgressScrollBar } from '@components/SectionComponents/ProgressScrollBar';

interface NavbarProps {
    navbar: MenuNavigationV2;
    preview?: boolean;
    shrink?: boolean;
}

export function Navbar({ navbar, preview, shrink }: NavbarProps): JSX.Element {
    const setNavHeight = useSetAtom(navHeightAtom);
    const [remSize] = useAtom(remSizeAtom);
    const menuNav = useRef<HTMLDivElement>(null);
    const language = getLanguageById(navbar.language)?.code || LanguageCode.en;

    useEffect(() => {
        if (menuNav.current && !preview) {
            setNavHeight(menuNav.current.clientHeight);
        }
    }, [setNavHeight, preview]);

    return (
        <>
            {navbar.isMicroNavVisible ? (
                <MicroMenu
                    microContent={navbar.microContent}
                    showLogin={navbar.showLoginButton}
                    showLanguageRegion={navbar.showLanguageRegion}
                    preview={preview}
                    shrink={shrink}
                    language={language}
                    loginText={navbar.loginText}
                    loginUrl={navbar.loginUrl}
                />
            ) : null}
            <div
                ref={menuNav}
                css={classes.stickyNavbar}
                style={remSize >= 14 ? { position: 'relative' } : undefined}
                data-testid='navbarV2'
                id='NavigationBar'
            >
                <MainMenu
                    mainContent={navbar.mainContent}
                    secondaryLogo={navbar.secondaryLogo}
                    secondaryLogoLink={navbar.secondaryLogoLink}
                    secondaryLogoLabel={navbar.secondaryLogoLabel}
                    secondaryLogoAlt={navbar.secondaryLogoAlt}
                    showSearch={navbar.showSearch}
                    preview={preview}
                    shrink={shrink}
                />
                <Divider css={{ opacity: 0.6 }} />
                <ProgressScrollBar />
            </div>
        </>
    );
}
